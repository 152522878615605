import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Typography, Container } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {},
  containerPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6em",
    },
    marginTop: "10em",
  },
  linksStyles: {
    textDecoration: "underline",
    color: theme.palette.common.black,
    fontWeight: 500,
    "&:hover": {
      color: "#291C64",
    },
  },
}))

export default function Index() {
  const classes = useStyles()
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CFL | Preguntas Frecuentes</title>
      </Helmet>
      <Container className={classes.containerPart}>
        <Box maxWidth="80%" m="auto">
          <Box mt={4} py={2}>
            <Typography variant="h1" component="h1" gutterBottom>
              <b>Preguntas Frequentes</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              <ul>
              <li>
                  <a href="#frequentQuestions" className={classes.linksStyles}>
                    Preguntas frecuentes
                  </a>
                </li>
                <li>
                  <a href="#applicantsQuestions" className={classes.linksStyles}>
                    Preguntas para el solicitante
                  </a>
                </li>
                <li>
                  <a href="#investorsQuestions" className={classes.linksStyles}>
                    Preguntas para el inversionista
                  </a>
                </li>
                <li>
                  <a href="#SecondaryMarketQuestions" className={classes.linksStyles}>
                    Mercado secundario
                  </a>
                </li>
              </ul>
            </Typography>
          </Box>
          <Box mt={4} py={2} id="frequentQuestions">
            <Typography variant="h3" gutterBottom inline>
              ¿Qué es Capital Funding Lab?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Somos una Institución de Tecnología Financiera y a través de
              nuestra plataforma facilitamos el otorgamiento de Créditos por
              Inversionistas a Solicitantes, no cobramos comisiones por la
              creación de tu usuario en nuestra plataforma, no solicitamos
              contar con un mínimo de fondos sin invertir y puedes comenzar a
              invertir en los proyectos que ponemos a tu alcance desde $20.00
              (Veinte pesos 00/100 M.N.).
            </Typography>
            <br />
            <Typography variant="h3" gutterBottom inline>
              ¿Cómo funciona Capital Funding Lab?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Una vez que se ha aprobado una solicitud de crédito y contamos con
              Inversionistas que desean otorgar crédito, CFL cuenta con un
              sistema mediante el cual ejecuta un algoritmo que asigna los
              préstamos entre Inversionistas y Solicitantes según las
              instrucciones de codificación. Las instrucciones de codificación
              están diseñadas en base a un sistema de colas en el cual los
              fondos de los Inversionistas se ordenan y asignan por orden de
              llegada y teniendo en cuenta los límites de diversificación; es
              decir, una vez que los fondos se han depositado en el Contrato de
              Cliente de un Inversionista y se ha seleccionado uno de los
              productos, la plataforma considera que los fondos están
              disponibles para ser asignados a un crédito. Así pues, los fondos
              de los Inversionistas se ordenan en este momento en un sistema de
              colas dándoles un "tiempo de espera promedio" en la cual sus
              fondos serán asignados a los préstamos solicitados.
            </Typography>
          </Box>
          <Box mt={4} py={2} id="applicantsQuestions">
            <Typography variant="h2" gutterBottom inline>
              Preguntas para el solicitante
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              ¿Qué se necesita para solicitar un crédito?
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              Para ser elegible como Solicitante deberá, entre otros aspectos:
              <ul>
                <li>Ser una persona física residente en México</li>
                <li>
                  No ser una Persona Relacionada de CFL ni tener poder de mando
                  en CFL
                </li>
                <li>
                  Contar con una cuenta de depósito de dinero abierta a su
                  nombre en alguna Entidad Financiera
                </li>
                <li>Datos académicos</li>
                <li>
                  Que no exista ni haya existido procedimiento judicial alguno
                  en su contra
                </li>
                <li>
                  No estar solicitando ningún otro tipo de financiamiento en
                  cualquier otra ITF
                </li>
                <li>
                  No estar solicitando el Crédito por un monto que excedan del
                  equivalente en moneda nacional a 7,370,000 UDI’s
                </li>
                <li>
                  Contar con al menos 3 años de antigüedad en su domicilio
                </li>
                <li>Residir en México</li>
                <li>
                  Tener al menos 18 años de edad al momento de la solicitud
                </li>
              </ul>
              Al aplicar en nombre y representación de una persona moral, el
              aplicante confirma y declara:
              <ul>
                <li>
                  Estar debidamente autorizado para actuar en nombre y
                  representación de la persona moral
                </li>
                <li>
                  Que la persona moral que representa cuenta con la capacidad
                  jurídica necesaria para celebrar contratos de comisión
                  mercantil
                </li>
                <li>
                  Que su representada no otorgue créditos al consumo en el curso
                  ordinario de su negocio o, de lo contrario, que cuente con los
                  permisos necesarios para actuar como Inversionista de dichos
                  créditos al consumo
                </li>
              </ul>
              A todos nuestros solicitantes se les requiere que tengan una
              garantía real y una garantía personal.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h3" gutterBottom inline>
              ¿Qué tipos de crédito se pueden contratar a través de Capital
              Funding Lab?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Nuestra plataforma ofrece 3 tipos de crédito con garantía
              hipotecaria, lo cuales son: “Liquidez”; “Liquidez de empresa” y
              “Hogar”, puedes conocer más de ellos en el siguiente link:{" "}
              <a
                href="https://www.capitalfundinglab.com/#Credito"
                target="_blank"
              >
                https://www.capitalfundinglab.com/#Credito
              </a>
              .
            </Typography>
          </Box>
          <Box py={2}>
            <Typography variant="h3" gutterBottom inline>
              ¿Se necesita contar con algún tipo de garantía?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Sí, CFL requiere a sus Solicitantes que tengan una garantía real y
              una garantía personal.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h3" gutterBottom inline>
              Cuando mi solicitud de crédito sea aprobada, ¿En cuánto tiempo se
              deposita a mi cuenta el importe de mi crédito?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              El depósito de los fondos del crédito, depende del tiempo en que
              se tarden en juntar los recursos del mismo, dependiendo de los
              recursos otorgados por los Inversionistas. No te preocupes CFL te
              acompañará en todo momento durante el proceso de contratación del
              crédito y te mantendrá informado a través de un asesor.
            </Typography>
          </Box>
          <Box mt={4} py={2} id="investorsQuestions">
            <Typography variant="h2" gutterBottom inline>
              Preguntas para el inversionista
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              ¿Qué se necesita para ser inversionista?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Para ser elegible como Inversionista deberá, entre otros aspectos:
              <ul>
                <li>
                  Ser una persona física residente en México o una persona moral
                  mexicana
                </li>
                <li>
                  Contar con una cuenta de depósito de dinero abierta a su
                  nombre en alguna Entidad Financiera
                </li>
                <li>
                  Aprobar nuestro examen de aptitud de inversionistas y firmar
                  nuestra constancia electrónica sobre riesgos
                </li>
              </ul>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h3" gutterBottom inline>
              ¿Qué tipo de contrato se firma con Capital Funding Lab?
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              Contrato de Comisión Mercantil.
            </Typography>
          </Box>
          <Box py={2}>
            <Typography variant="h3" gutterBottom inline>
              ¿Dónde puedo consultar los productos en los cuáles se puede
              invertir?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Una vez que hayas creado tu usuario en CFL, en el apartado de
              Marketplace dentro de tu perfil, podrás encontrar los productos
              para inversión.
              <br />
              <br />
              <a
                href="https://app.capitalfundinglab.com/app/transactions/invest "
                target="_blank"
              >
                https://app.capitalfundinglab.com/app/transactions/invest
              </a>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h3" gutterBottom inline>
              ¿Cuándo puedo retirar los fondos de mi inversión?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Los fondos de tu inversión junto con los posibles rendimientos
              generados podrán ser retirados hasta el final del plazo forzoso de
              la inversión correspondiente, sin embargo, en caso de que
              requieras retirar el dinero de manera anticipada, CFL pone a tu
              disposición un mercado secundario, en el cual tu participación en
              el o los créditos respectivos se ponen a la venta, para que otro u
              otros Inversionistas puedan adquirirla.
            </Typography>
          </Box>
          <Box py={2}>
            <Typography variant="h3" gutterBottom inline>
              ¿Cómo se dispersa el dinero de mi inversión?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Una vez que los fondos se han depositado en el Contrato de Cliente
              de un Inversionista y se ha seleccionado uno de los productos, la
              plataforma considera que los fondos están disponibles para ser
              asignados a un crédito. Así pues, los fondos de los Inversionistas
              se ordenan en este momento en un sistema de colas dándoles un
              "tiempo de espera promedio" en la cual sus fondos serán asignados
              a los préstamos solicitados.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h3" gutterBottom inline>
              ¿Cómo puedo invertir mi dinero una vez que se llegó a su fin el
              plazo de mi inversión?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Todos nuestros productos cuentan con una reinversión automática,
              la cual tiene que ser autorizada por el Inversionista al momento
              de la celebración del contrato de comisión mercantil, por lo que
              al término del plazo de tu inversión, los fondos de la misma y los
              posibles rendimientos generados serán reinvertidos.
              <br /> <br />
              Sin embargo, previo al término del plazo de la inversión, se te
              notificará a fin de que si no deseas que sean reinvertidos tus
              recursos nos lo hagas saber.
            </Typography>
          </Box>
          <Box py={2}>
            <Typography variant="h3" gutterBottom inline>
              ¿Dónde puedo consultar los posibles rendimientos de mi inversión?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Dentro de tu perfil de usuario, en el apartado de “Detalles” del
              producto donde hayas invertido se encuentra la sección de “Mis
              Movimientos”, en la cual se encuentran los intereses generados por
              tu inversión.
            </Typography>
          </Box>
          <Box py={2}>
            <Typography variant="h3" gutterBottom inline>
              ¿A partir de qué decido invertir en un producto cuando comienzo a
              ver rendimientos?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Podrás observar los rendimientos de tu inversión tan pronto como
              sean dispersados los recursos a los préstamos financiados.
            </Typography>
          </Box>
          <Box py={2}>
            <Typography variant="h3" gutterBottom inline>
              ¿Qué riesgos tiene mi inversión?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Cómo toda inversión nuestros productos conllevan riesgos de
              pérdida, sin embargo, al estar garantizados los créditos que
              otorgan los Inversionistas a los Solicitantes por una garantía
              real y en casos específicos por otra personal hace más factible la
              recuperación en caso de incumplimiento.
              <br /> <br />
              Aunado a lo anterior, CFL tiene el derecho a retener el desembolso
              del crédito a los Solicitantes hasta el momento en que dicha
              garantía se encuentre debidamente constituida y perfeccionada en
              favor de CFL, en nombre y para beneficio de los Inversionistas.
            </Typography>
          </Box>
          <Box py={2}>
            <Typography variant="h3" gutterBottom inline>
              ¿Qué pasa si un solicitante no paga las amortizaciones de su
              crédito?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              COBRANZA EXTRA JUDICIAL
              <br /> <br />
              En caso de incumplimiento del Solicitante bajo los términos de un
              Contrato de Crédito, CFL iniciará acciones para recuperar la deuda
              pendiente bajo dicho Contrato de Crédito y tomará las medidas que
              sean razonablemente necesarias, legales y apropiadas para
              recuperar cualquier cantidad pendiente bajo el Contrato de
              Crédito. Cualesquier sumas recuperadas por CFL (o por la Agencia
              de Cobranzas) serán pagadas a los Inversionistas correspondientes,
              en cualquier caso, sujeto a la deducción de los costos de
              recuperación en los que incurran CFL y/o la Agencia de Cobranzas
              (los cuales serán prorrateados de acuerdo con la proporción que
              cada Parte del Crédito correspondiente represente con respecto al
              monto total del Crédito de que se trate).
              <br /> <br />
              COBRANZA JUDICIAL
              <br /> <br />
              Para efectos de lo anterior, en caso de que algún Crédito sea
              determinado como incobrable conforme a la Política de Cobranza de
              CFL, se mandará el expediente al departamento legal para gestionar
              la ejecución de la garantía correspondiente en términos de la
              legislación aplicable.
            </Typography>
          </Box>
          <Box py={2}>
            <Typography variant="h3" gutterBottom inline>
              ¿Cómo se elige a un solicitante de crédito?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              CFL hace todos los trámites necesarios para comprobar su identidad
              y hacer verificaciones de prevención de fraudes y crediticias;
              Valida los datos y verifica la autenticidad de los documentos
              proporcionados por el solicitante de manera digital; Obtiene una
              Calificación Crediticia para por parte de las sociedades de
              información crediticia de las que somos usuarios; CFL, Realiza
              controles de prevención de lavado de dinero y financiamiento al
              terrorismo.
              <br /> <br />
              Finalmente CFL se reserva el derecho de rechazar cualquier
              aplicación para ser Solicitante, a nuestra entera discreción en
              caso de que a su criterio no cumpla con los estándares requeridos.
            </Typography>
          </Box>

          <Box mt={4} py={2} id="SecondaryMarketQuestions">
            <Typography variant="h2" gutterBottom inline>
              Mercado secundario
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              ¿Cómo funciona el mercado secundario?
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              El Mercado Secundario permite que los inversionistas que quieran
              retirar sus fondos con anterioridad al plazo de financiamiento de
              sus préstamos o partes de préstamos puedan solicitar vender o
              ceder sus derechos a otro(s) inversionista(s), pasando los
              derechos de cobro de sus préstamos o partes de préstamos a los
              mismos, permitiendo así ofrecer un servicio más flexible a
              nuestros clientes.
              <br /> <br />
              El Inversionista deberá solicitar el retiro de sus fondos
              previamente invertidos a través de la Plataforma, momento a partir
              del cual el sistema automático de venta secundaria implementado en
              la misma los pondrá en venta. Una vez el sistema automático de
              venta secundaria identifique a un posible “Inversionista”
              adquirente, se enviará una notificación al Inversionista que haya
              solicitado el retiro de su inversión. El Inversionista que desea
              retirar sus fondos y el Inversionista adquirente celebrarán un
              Contrato de Cesión y Adhesión conforme al proceso previsto para
              tal efecto en la Plataforma.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}
